import * as React from 'react'
import { Helmet } from "react-helmet";
import './reset.module.scss'
import { container, header, logotype, main, footer } from './layout.module.scss'

const currentYear = new Date().getFullYear();

const Layout = ({ pageTitle, metaTitle, children }) => {
    return (
        <section className={container}>
            <Helmet title={metaTitle} defer={false} />
            <header className={header}>
                <div className={logotype}>
                    REAZZON
                </div>
                {/*<nav>*/}
                {/*    <ul className={navigation}>*/}
                {/*        <li className={navigation_item}><Link to="/">Главная</Link></li>*/}
                {/*        <li className={navigation_item}><Link to="/plugins">Плагины</Link></li>*/}
                {/*    </ul>*/}
                {/*</nav>*/}
            </header>
            <main className={main}>
                <h1>{pageTitle}</h1>
                {children}
            </main>
            <footer className={footer}>
                <span>REAZZON &copy; {currentYear}</span>
            </footer>
        </section>
    )
}
export default Layout
