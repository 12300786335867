import * as React from 'react'
import { specialBlock, specialBlock_icon, specialBlock_title, specialBlock_caption } from './special.module.scss'

const Special = (props) => {
	return (
		<div className={specialBlock}>
			<span className={specialBlock_icon} dangerouslySetInnerHTML={{__html: props.icon}}/>
			<span className={specialBlock_title}>{props.title}</span>
			<span className={specialBlock_caption}>{props.caption}</span>
		</div>
	);
};

export default Special