import * as React from 'react'
import { technologyBlock, technologyBlock_icon } from './technology.module.scss'

const Technology = (props) => {
	return (
		<div className={technologyBlock}>
			<span className={technologyBlock_icon} dangerouslySetInnerHTML={{__html: props.icon}}/>
		</div>
	);
};

export default Technology